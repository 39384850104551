import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useFeature from '~/client/hooks/feature/useFeature';
import useSite from '~/client/hooks/useSite';
import useAppContext from '~/shared/context/AppContext/useAppContext';

type Args = {
  isRegister?: boolean;
};

const useReCaptchaV3 = ({ isRegister }: Args) => {
  const { isBridgeSite } = useSite();
  const isRecaptchaV3Enabled = useFeature('isRecaptchaV3Enabled');
  const {
    captchaConfig: { isLoginCaptchaRequired, isRegistrationCaptchaRequired },
  } = useAppContext();
  const isCaptchaRequired = isRegister
    ? isRegistrationCaptchaRequired
    : isLoginCaptchaRequired;
  const isRecaptchaV3 =
    isRecaptchaV3Enabled && !isCaptchaRequired && !isBridgeSite;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getV3Token = async () => {
    if (executeRecaptcha && isRecaptchaV3) {
      return (await executeRecaptcha()) ?? '';
    }
    return '';
  };
  return { getV3Token, captchaVersion: isRecaptchaV3 ? 'v3' : 'v2' };
};

export default useReCaptchaV3;
