import styled from 'styled-components';
import Stack from '../Stack';

const InlineStack = styled(Stack)`
  && {
    grid-auto-flow: column;
    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
    ${({ gridGap }) => gridGap && `grid-gap: ${gridGap};`}
  }
`;

export default InlineStack;
