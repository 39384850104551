import { useContext } from 'react';
import StepByStepAuthContext from '../StepByStepAuthContext';

const useStepByStepAuthContext = () => {
  const context = useContext(StepByStepAuthContext);

  if (context === undefined) {
    throw new Error(
      'useStepByStepAuthContext was used outside of its Provider',
    );
  }
  return context;
};

export default useStepByStepAuthContext;
