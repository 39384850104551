import useThemeContext from '~/client/hooks/theme/useThemeContext';
import Img from '../../images/Img';
import getCdnLogoImg from '../getCdnLogoImg';
import type LogoProps from '../LogoProps';

const LogoEmpire: FC<LogoProps> = ({ short, sendToGtm }) => {
  const { colorScheme } = useThemeContext();
  const filePostfix = (colorScheme || 'dark') === 'dark' ? 'Dark' : '';

  if (short) {
    return (
      <Img
        src={getCdnLogoImg(`empire${filePostfix}Short.svg`)}
        onClick={sendToGtm}
        width={39}
      />
    );
  }

  return (
    <Img
      src={getCdnLogoImg(`empire${filePostfix}Full.svg`)}
      onClick={sendToGtm}
      width={144}
      id="logo-empire"
    />
  );
};

export default LogoEmpire;
