const deserializeErrors = (errors: GenericErrorType[]) => {
  if (!errors) {
    return {};
  }
  return errors.reduce((acc, error) => {
    const key = error?.params?.[0]?.value || 'global';
    return {
      ...acc,
      [key]: [error?.message],
    } as DeserializeError;
  }, {});
};

export default deserializeErrors;
