import { mergeClassnames } from '@heathmont/moon-core-tw';
import dynamic from 'next/dynamic';
import useIsStepByStepEnabled from '~/client/components/Auth/Authenticate/AuthSteps/hooks/useIsStepByStepEnabled';
import useAbTest from '~/client/hooks/useAbTest';
import useSite from '~/client/hooks/useSite';
import Translate from '~/client/shared/i18n/Translate';
import HeaderWrapper from './HeaderWrapper';
import StepByStepAuthBrandHeading from './StepByStepAuthBrandHeading';

const OneCtaRegistrationAbTest = dynamic(
  () =>
    import(
      '~/client/components/Auth/Register/RegisterBody/OneCtaRegistrationAbTest'
    ),
);

type Props = {
  id: string;
  isPopover?: boolean;
};

const AuthBrandHeading: FC<Props> = ({ id, isPopover }) => {
  const { isBitcasino } = useSite();
  const isStepByStepRegistrationEnabled = useIsStepByStepEnabled();

  const { showVariant1, showVariant2 } = useAbTest({
    name: 'oneCtaRegistration',
    device: 'desktop',
  });
  const showSignIn = showVariant1 || showVariant2;

  if (showSignIn) {
    return <OneCtaRegistrationAbTest />;
  }
  const isResetPassword = id === 'profile.request_pw_reset';
  if (isStepByStepRegistrationEnabled && !isResetPassword) {
    return <StepByStepAuthBrandHeading isPopover={isPopover} />;
  }

  return (
    <HeaderWrapper>
      <h1
        className={mergeClassnames(
          'text-moon-24 font-semibold text-bulma',
          isBitcasino && 'font-headline font-normal',
        )}
      >
        <Translate id={id} />
      </h1>
    </HeaderWrapper>
  );
};

export default AuthBrandHeading;
