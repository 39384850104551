import { mergeClassnames } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import LogoLink from '~/client/layout/Sidebar/LogoLink';
import Logo from '../../Logo';

type Props = {
  children: React.ReactNode;
  isPopover?: boolean;
};

const HeaderWrapper: FC<Props> = ({ children, isPopover = false }) => {
  const { isMobile } = useAgentData();

  if (isPopover) {
    return <>{children}</>;
  }
  return (
    <div className={mergeClassnames('grid gap-12', isMobile && 'mt-0 gap-4')}>
      <LogoLink href="/">
        <Logo />
      </LogoLink>
      {children}
    </div>
  );
};
export default HeaderWrapper;
