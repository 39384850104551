import dynamic from 'next/dynamic';
import useSite from '~/client/hooks/useSite';
import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';
import LogoEmpire from './LogoEmpire';
import sendEventToUmami from '@/client/helpers/trackEvent/sendEventToUmami';

const LogoBitcasino = dynamic(() => import('./LogoBitcasino'));
const LogoSportsbet = dynamic(() => import('./LogoSportsbet'));
const LogoSportsbetCanada = dynamic(() => import('./LogoSportsbetCanada'));
const LogoSbIo = dynamic(() => import('./LogoSbIo'));
const LogoLivecasino = dynamic(() => import('./LogoLivecasino'));
const LogoSportsbetMx = dynamic(() => import('./LogoSportsbetMx'));

const Logo: FC<{
  short?: boolean;
  gtmLocation?: string;
  className?: string;
}> = ({ short, gtmLocation = '' }) => {
  const { brand } = useSite();

  const getLogoWidth = () => {
    if (short) {
      return brand === 'bitcasino' ? 'rem:w-[20px]' : 'rem:w-[30px]';
    }
    return brand === 'bitcasino' ? 'rem:w-[130px]' : 'rem:w-[150px]';
  };

  const logoWidth = getLogoWidth();

  const sendToGtm = () => {
    sendEventToUmami(
      {
        menu: 'logo',
      },
      'desktopSideMenuClick',
    );

    return sendInteractionToGtm({
      eventCategory: 'brand_logo',
      eventAction: gtmLocation,
    });
  };

  switch (brand) {
    case 'bitcasino':
      return (
        <LogoBitcasino
          short={short}
          sendToGtm={sendToGtm}
          className={logoWidth}
        />
      );
    case 'sportsbet':
      return (
        <LogoSportsbet
          short={short}
          className={logoWidth}
          sendToGtm={sendToGtm}
        />
      );
    case 'sportsbetca':
      return (
        <LogoSportsbetCanada
          short={short}
          className={logoWidth}
          sendToGtm={sendToGtm}
        />
      );
    case 'sbio':
      return <LogoSbIo sendToGtm={sendToGtm} />;
    case 'livecasino':
      return <LogoLivecasino short={short} sendToGtm={sendToGtm} />;
    case 'sportsbetmx':
      return (
        <LogoSportsbetMx
          short={short}
          className={logoWidth}
          sendToGtm={sendToGtm}
        />
      );
    case 'empire':
      return <LogoEmpire short={short} sendToGtm={sendToGtm} />;
    default:
      return null;
  }
};

export default Logo;
