import { Heading } from '@heathmont/moon-core';
import AuthStepsEnum from '~/client/components/Auth/Authenticate/AuthSteps/AuthStepsEnum';
import useAgentData from '~/client/hooks/useAgentData';
import Translate from '~/client/shared/i18n/Translate';
import InlineStack from '~/client/shared/InlineStack';
import BackButton from './BackButton';
import useAuthBrandHeading from './useAuthBrandHeading';
import HeaderWrapper from '../HeaderWrapper';

type Props = {
  isPopover?: boolean;
};
const AuthBrandHeading: FC<Props> = ({ isPopover = false }) => {
  const { step, id, handleBackClick } = useAuthBrandHeading();
  const { isMobile } = useAgentData();
  const isEmailOrUsername = step === AuthStepsEnum.EmailOrUsername;
  const mobileColumns = isEmailOrUsername ? '1fr' : '0.5fr';
  const columns = isEmailOrUsername ? '1fr' : '0.1fr';

  return (
    <HeaderWrapper isPopover={isPopover}>
      <InlineStack templateColumns={isMobile ? mobileColumns : columns}>
        <BackButton onClick={() => handleBackClick()} />
        <Heading size={20} textAlign={isPopover ? 'center' : 'left'}>
          <Translate id={id} />
        </Heading>
      </InlineStack>
    </HeaderWrapper>
  );
};

export default AuthBrandHeading;
