import { Button } from '@heathmont/moon-components';
import { ControlsChevronLeft } from '@heathmont/moon-icons';
import { rem } from '@heathmont/moon-utils';
import styled from 'styled-components';
import AuthStepsEnum from '~/client/components/Auth/Authenticate/AuthSteps/AuthStepsEnum';
import useStepByStepAuthContext from '~/client/components/Auth/Authenticate/AuthSteps/context/hooks/useStepByStepAuthContext';

type Props = { onClick: () => void };

const BackButton: FC<Props> = ({ onClick }) => {
  const { step } = useStepByStepAuthContext();
  if (step === AuthStepsEnum.EmailOrUsername) {
    return null;
  }
  return (
    <ButtonStyled onClick={onClick}>
      <ControlsChevronLeft color="bulma.100" fontSize={rem(20)} />
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)`
  && {
    width: min-content;
  }
`;

export default BackButton;
