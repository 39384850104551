import { mergeClassnames } from '@heathmont/moon-core-tw';
import NextLink from '~/client/shared/NextLink';

const LogoLink: FC<{ href: string; className?: string }> = ({
  children,
  className,
  href,
}) => (
  <NextLink
    className={mergeClassnames('self-center text-bulma', className)}
    href={href}
  >
    {children}
  </NextLink>
);

export default LogoLink;
