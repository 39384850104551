import isSsr from '~/shared/utils/isSsr';

const SessionStorage = (() => ({
  getItem: <T>(name: string, defaultValue?: T) => {
    if (isSsr()) {
      return defaultValue;
    }
    try {
      return JSON.parse(sessionStorage.getItem(name) ?? '') as T;
    } catch (error) {
      return defaultValue;
    }
  },
  setItem: (name: string, value: unknown) => {
    if (isSsr()) {
      return;
    }
    sessionStorage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name: string) => {
    if (isSsr()) {
      return;
    }
    sessionStorage.removeItem(name);
  },
}))();

export default SessionStorage;
