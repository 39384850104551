import { useCallback } from 'react';
import useCookie from '~/shared/hooks/useCookie';

const useSocialAuthType = () => {
  const [socialAuthType, set, clear] = useCookie<string>('social_auth_type');
  const setSocialAuthType = useCallback((value: string) => set(value), [set]);

  return [socialAuthType, setSocialAuthType, clear] as const;
};

export default useSocialAuthType;
