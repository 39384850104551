import { Stack as BaseStack } from '@heathmont/moon-components';
import { themed } from '@heathmont/moon-utils';
import styled, { css } from 'styled-components';

type Props = {
  justifyContent?: string;
  justifyItems?: string;
  justifySelf?: string;
  alignItems?: string;
  alignContent?: string;
  alignSelf?: string;
  placeItems?: string;
  placeSelf?: string;
  templateColumns?: string;
  templateRows?: string;
  templateAreas?: string;
  borderRadius?: string;
  padding?: string;
  relative?: boolean;
  absolute?: boolean;
  background?: string;
  autoFlow?: string;
  overflow?: string;
  margin?: string;
  marginTop?: string;
  marginBottom?: string;
  zIndex?: number;
  autoColumns?: string;
  autoRows?: 'min-content' | 'max-content' | 'auto';
  direction?: 'ltr' | 'rtl';
  maxWidth?: string;
  maxHeight?: string;
  width?: string;
  height?: string;
  gridArea?: string;
  center?: boolean;
  minWidth?: string;
  minHeight?: string;
  gridGap?: string;
  gridColumn?: string;
  gridRow?: string;
};

const Stack = styled(BaseStack)<Props>`
  && {
    justify-content: ${({ justifyContent }) =>
      justifyContent && justifyContent};
    justify-items: ${({ justifyItems }) => justifyItems && justifyItems};
    justify-self: ${({ justifySelf }) => justifySelf && justifySelf};
    align-items: ${({ alignItems }) => alignItems && alignItems};
    align-content: ${({ alignContent }) => alignContent && alignContent};
    align-self: ${({ alignSelf }) => alignSelf && alignSelf};
    place-items: ${({ placeItems }) => placeItems && placeItems};
    place-self: ${({ placeSelf }) => placeSelf && placeSelf};
    grid-template-columns: ${({ templateColumns }) =>
      templateColumns && templateColumns};
    grid-template-rows: ${({ templateRows }) => templateRows && templateRows};
    border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
    padding: ${({ padding }) => padding && padding};
    background: ${({ background, theme }) =>
      background && (themed('color', background)(theme) || background)};
    ${({ relative }) => relative && 'position: relative'};
    ${({ absolute }) => absolute && 'position: absolute'};
    grid-auto-flow: ${({ autoFlow }) => autoFlow && autoFlow};
    overflow: ${({ overflow }) => overflow && overflow};
    margin: ${({ margin }) => margin && margin};
    margin-top: ${({ marginTop }) => marginTop && marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
    z-index: ${({ zIndex }) => zIndex && zIndex};
    width: ${({ width }) => width && width};
    height: ${({ height }) => height && height};
    grid-area: ${({ gridArea }) => gridArea && gridArea};
    ${({ templateAreas }) =>
      templateAreas && `grid-template-areas: "${templateAreas}"`};
    ${({ autoColumns }) => autoColumns && `grid-auto-columns: ${autoColumns}`};
    ${({ autoRows }) => autoRows && `grid-auto-rows: ${autoRows}`};
    ${({ gridColumn }) => gridColumn && `grid-column: ${gridColumn}`};
    ${({ gridRow }) => gridRow && `grid-column: ${gridRow}`};
    z-index: ${({ zIndex }) => zIndex && zIndex};
    ${({ center }) => center && centerStyle};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
    ${({ gridGap }) => gridGap && `grid-gap: ${gridGap}`};
    ${({ direction }) => direction && `direction: ${direction}`};
  }
`;

const centerStyle = css`
  align-items: center;
  justify-content: center;
`;

export default Stack;
