import AuthStepsEnum from '~/client/components/Auth/Authenticate/AuthSteps/AuthStepsEnum';
import useStepByStepAuthContext from '~/client/components/Auth/Authenticate/AuthSteps/context/hooks/useStepByStepAuthContext';
import useLoginOrSignupSubmit from '~/client/components/Auth/Authenticate/AuthSteps/hooks/helpers/useLoginOrSignupSubmit';
import useSocialAuthType from '~/client/hooks/cookies/useSocialAuthType';
import useLoginError from '~/client/hooks/user/useLoginError';

const useAuthBrandHeading = () => {
  const { step, setStep, clearErrors } = useStepByStepAuthContext();
  const { socialAuthType } = useLoginOrSignupSubmit({});
  const [, setSocialAuthType] = useSocialAuthType();
  const { resetError } = useLoginError();

  const handleBackClick = () => {
    clearErrors?.();
    if (socialAuthType && step === AuthStepsEnum.MoreOptions) {
      resetError();
      return setStep(AuthStepsEnum.EnterSms2fa);
    }
    if (socialAuthType && step === AuthStepsEnum.FinishSignUp) {
      resetError();
      setSocialAuthType('');
      return setStep(AuthStepsEnum.EmailOrUsername);
    }
    if (step === AuthStepsEnum.EnterSms2fa) {
      return setStep(AuthStepsEnum.EnterPassword);
    }
    if (step === AuthStepsEnum.MoreOptions) {
      return setStep(AuthStepsEnum.EnterSms2fa);
    }
    return setStep(AuthStepsEnum.EmailOrUsername);
  };
  const setTranslationId = () => {
    switch (step) {
      case AuthStepsEnum.EmailOrUsername:
        return 'profile.login_or_signup';
      case AuthStepsEnum.FinishSignUp:
        return 'profile.finish_signup';
      case AuthStepsEnum.EnterPassword:
        return 'profile.login';
      case AuthStepsEnum.EnterSms2fa:
        return 'profile.2fa';
      case AuthStepsEnum.MoreOptions:
        return 'profile.more_options';
      default:
        return '';
    }
  };
  const id = setTranslationId();
  return {
    step,
    id,
    handleBackClick,
  };
};

export default useAuthBrandHeading;
