import { useState } from 'react';
import useRouter from '~/client/hooks/router/useRouter';
import updateQueryParam from '~/shared/utils/updateQueryParam';
import useDidUpdate from '../lifecycle/useDidUpdate';

const useQueryParam = (name: string) => {
  const router = useRouter();
  const query = router.query[name]?.toString() ?? '';
  const [localQuery, setQuery] = useState(query);

  useDidUpdate(() => setQuery(query), [query]);
  useDidUpdate(() => updateQueryParam(name, localQuery), [name, localQuery]);

  const removeQuery = () => {
    const newQuery = router.query ?? {};
    delete newQuery[name];
    router.replace({ query: newQuery }, undefined, { shallow: true });
  };

  const encodedQuery = encodeURI(localQuery);
  return [decodeURI(encodedQuery), setQuery, removeQuery] as const;
};

export default useQueryParam;
