import { useCallback, useEffect, useState } from 'react';
import useReCaptchaV3 from '~/client/components/Auth/Shared/utils/useReCaptchaV3';
import useSocialAuthType from '~/client/hooks/cookies/useSocialAuthType';
import useQueryParam from '~/client/hooks/router/useQueryParam';
import useLoginError from '~/client/hooks/user/useLoginError';
import useSite from '~/client/hooks/useSite';
import deserializeErrors from '~/shared/utils/errors/deserializeErrors';
import SessionStorage from '~/shared/utils/storage/SessionStorage';
import AuthStepsEnum from '../../AuthStepsEnum';
import useStepByStepAuthContext from '../../context/hooks/useStepByStepAuthContext';
import { useGetAuthClearanceMutation } from '../../graphql/GetAuthClearanceMutation.generated';
import { useValidateInputMutation } from '../../graphql/ValidateInputMutation.generated';

type Props = {
  onContinueClick?: (step: AuthStepsEnum) => void;
};

const useLoginOrSignupSubmit = ({ onContinueClick }: Props) => {
  const {
    isEmail,
    authClearanceId,
    setAuthClearanceId,
    setError,
    setErrors,
    clearErrors,
    handleSubmit,
    setStep,
    setValue,
    registrationCaptchaRequired: captchaRequired,
    setRegistrationCaptchaRequired: setCaptchaRequired,
  } = useStepByStepAuthContext();
  const [loading, setLoading] = useState(false);
  const { site } = useSite();
  const { getV3Token, captchaVersion } = useReCaptchaV3({ isRegister: true });
  const [getAuthClearance] = useGetAuthClearanceMutation();
  const [validateInput] = useValidateInputMutation();
  const [initialSocialAuthType] = useSocialAuthType();
  const [socialAuthType, setSocialAuthType] = useState(initialSocialAuthType);
  const { error: loginError } = useLoginError();
  const missingFields = SessionStorage.getItem('social_auth_missing_fields');
  const [isMetamask] = useQueryParam('metamask');
  const [isTonConnect] = useQueryParam('tonconnect');
  const isWalletAuthConnection = isMetamask || isTonConnect;

  const onSocialAuth = useCallback(() => {
    if (socialAuthType) {
      if (missingFields || isWalletAuthConnection) {
        setStep(AuthStepsEnum.FinishSignUp);
      }
      if (loginError.includes('SMS_2FA')) {
        setStep(AuthStepsEnum.EnterSms2fa);
      }
      if (loginError.includes('OTP')) {
        setValue?.('uses2FA', true);
        setStep(AuthStepsEnum.EnterSms2fa);
      }
    }
  }, [
    loginError,
    setStep,
    setValue,
    socialAuthType,
    missingFields,
    isWalletAuthConnection,
  ]);

  useEffect(() => {
    if (initialSocialAuthType) {
      setSocialAuthType(initialSocialAuthType);
    }
  }, [initialSocialAuthType]);

  useEffect(() => {
    onSocialAuth();
  }, [onSocialAuth]);

  const submit = async (values: AuthFormValues) => {
    setLoading(true);
    clearErrors?.();

    const { username: emailOrUsername, captcha } = values;
    const isRecaptchaV3 = captchaVersion === 'v3' && !captchaRequired;
    const version = isRecaptchaV3 ? 'v3' : 'v2';
    const captchaToken = isRecaptchaV3 ? await getV3Token() : captcha;

    const { data: authClearanceData } = !authClearanceId
      ? await getAuthClearance({
          variables: {
            input: {
              captcha: captchaToken,
              captchaVersion: version,
              site,
              username: emailOrUsername,
            },
          },
        })
      : { data: null };

    const id =
      authClearanceData?.userManagementGetAuthClearance?.authClearanceId;

    const authClearanceErrors =
      authClearanceData?.userManagementGetAuthClearance?.errors;
    if (authClearanceErrors?.length) {
      const deserializedErrors = deserializeErrors(
        authClearanceErrors,
      ) as DeserializeError;
      if (deserializedErrors?.captcha) {
        setCaptchaRequired(true);
        setAuthClearanceId('');
        setLoading(false);
        return;
      }
    }

    if (authClearanceData) {
      setAuthClearanceId(id ?? '');
    }

    const { data } = await validateInput({
      variables: {
        input: {
          email: isEmail ? emailOrUsername : undefined,
          username: isEmail ? undefined : emailOrUsername,
          captcha: captchaToken,
          site,
          captchaVersion: version,
          authClearanceId: authClearanceId ? authClearanceId : id,
        },
      },
    });
    const validationErrors = data?.userManagementValidateInput?.errors;

    if (validationErrors?.length) {
      setErrors?.(validationErrors);
      const deserializedErrors = deserializeErrors(
        validationErrors,
      ) as DeserializeError;
      if (deserializedErrors?.captcha) {
        setCaptchaRequired(true);
        setAuthClearanceId('');
      }
      if (
        deserializedErrors?.email?.includes('EMAIL_ALREADY_EXISTS') ||
        deserializedErrors?.username?.includes('USERNAME_ALREADY_EXISTS')
      ) {
        clearErrors?.();
        onContinueClick?.(AuthStepsEnum.EnterPassword);
        return;
      }
      if (deserializedErrors?.username?.includes('INVALID_USERNAME')) {
        setError?.('username', {
          message: 'profile.INVALID_USERNAME_OR_EMAIL',
        });
        setLoading(false);
        return;
      }
    }
    onContinueClick?.(AuthStepsEnum.FinishSignUp);
  };

  return {
    loading,
    handleSubmit: handleSubmit(async (values) => {
      submit(values);
    }),
    socialAuthType,
    setSocialAuthType,
  };
};

export default useLoginOrSignupSubmit;
